<template>
  <div
    class="home"
    :style="!isNoXCX ? 'padding-top: 0;' : ''"
  >
    <template v-if="isNoXCX">
      <div class="all-title">我的数字藏品</div>
    </template>
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      :immediate-check="false"
      :finished-text="list.length ? '我也是有底线的' : '暂无数据'"
      @load="loadMoreData"
    >
      <div
        class="user flex align-items-c"
        @click="changeUserAction"
      >
        <div class="avatar">
          <img
            v-if="avatar"
            :src="avatar"
          >
        </div>
        <div class="user-center flex-one">
          <div class="name">{{ uname }}</div>
          <template v-if="nft_info.status == 1 && nft_info.info">
            <div class="flex no">
              <div class="flex-one line1">区块链地址：{{ nft_info.info.account }}</div>
              <div class="txt">复制</div>
              <div
                class="img"
                @click.stop="showEWMAction(nft_info.info.account_qrcode)"
              >查看二维码</div>
            </div>
          </template>
        </div>
        <div
          v-if="nft_info.status == 0"
          class="user-btn"
        >开通数字藏品账户</div>
      </div>

      <van-sticky :offset-top="isNoXCX ? (110 / 75) * fix : 0">
        <div class="tags flex">
          <div
            class="tag"
            :class="{ 'curr': type == 0 }"
            @click="changeTypeAction(0)"
          >我的藏品<div class="bg"></div>
          </div>
          <div
            class="tag"
            :class="{ 'curr': type == 1 }"
            @click="changeTypeAction(1)"
          >我赠出的 <div class="bg"></div>
          </div>
          <div
            class="tag"
            :class="{ 'curr': type == 2 }"
            @click="changeTypeAction(2)"
          >我收到的 <div class="bg"></div>
          </div>
        </div>
      </van-sticky>
      <div class="list">
        <div
          v-for="(m, i) in list"
          :key="i"
          class="item"
        >
          <div class="item-top flex">
            <div class="img">
              <van-image
                style="width: 100%;height: 100%;"
                :src="m.product.image + (m.product.image.toLowerCase().indexOf('.gif') !== -1 ? '' : '!m640')"
                fit="contain"
              ></van-image>
            </div>
            <div class="item-t-right">
              <div class="name">{{ m.product.store_name }}</div>
              <div class="time">{{ m.create_time }}</div>
              <div
                v-if="m.status == 1 && uid == m.own_uid && type == 0"
                class="go-send"
                @click.stop="giveSendAction(m)"
              >
                <div>转赠</div>
              </div>
            </div>
          </div>
          <div
            class="item-bottom flex align-items-c"
            @click="goDetailAction(m)"
          >
            <img
              src="https://artimg2.artart.cn/image/20220501/e6e538f3fd79b5c2fdc9aa33d0fd7571.png"
              alt=""
            >
            <div class="sdk line1">{{ m.tx_hash }}</div>
            <template v-if="type == 0">
              <div
                v-if="m.status == 1 && uid == m.own_uid"
                class="btn"
              >查看详情</div>
              <div
                v-else-if="m.status == 2"
                class="btn"
              >转赠中</div>
              <div
                v-else-if="m.status == 0"
                class="btn"
              >处理中</div>
            </template>
          </div>
        </div>
      </div>
    </van-list>
    <Footer />
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { Image as VanImage, Sticky, Toast, ImagePreview } from "vant";
import { useStore } from "vuex";
import { getRem } from "@/utils/rem";
import { ownListApi, transfersListApi } from "@/api/nft";
import { useRouter } from "vue-router";
import { returnWxPayType } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Sticky.name]: Sticky,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const hasMore = ref(false);
    const type = ref(0);
    const store = useStore();
    const accountInfo = ref({});
    const list = ref([]);
    const params = ref({
      page: 1,
      limit: 10,
      type: 0,
    });

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      store.dispatch("user/getNftInfo");
      loadList();
    });

    // const changeUserAction = (code) => {
    //   if (accountInfo.value.status === 0) {
    //     router.push("./register_nft");
    //   } else if (accountInfo.value.status === 1) {
    //     this.$copyText(this.message).then(
    //       function (e) {
    //         alert("Copied");
    //         console.log(e);
    //       },
    //       function (e) {
    //         alert("Can not copy");
    //         console.log(e);
    //       }
    //     );
    //     var input = document.createElement("input"); // 直接构建input
    //     input.value = code; // 设置内容
    //     console.log(input.value);

    //     document.body.appendChild(input); // 添加临时实例
    //     input.select(); // 选择实例内容
    //     document.execCommand("Copy"); // 执行复制
    //     document.body.removeChild(input); // 删除临时实例
    //     Toast("复制区块链地址成功");
    //   }
    // };

    const uid = computed(() => {
      return store.getters.uid;
    });
    const uname = computed(() => {
      return store.getters.uname;
    });
    const avatar = computed(() => {
      return store.getters.avatar;
    });
    const nft_info = computed(() => {
      return store.getters.nft_info;
    });
    const fix = computed(() => {
      return getRem();
    });
    const loadMoreData = () => {
      // 查看更多
      if (hasMore.value) {
        isLoading.value = true;
        params.value.page = params.value.page + 1;
        loadList();
      }
    };

    const loadList = () => {
      // params.value
      let action = transfersListApi;
      if (params.value.type === 0) {
        action = ownListApi;
      }
      action(params.value)
        .then((res) => {
          if (params.value.type !== 0) {
            res.data.list.forEach((m) => {
              m.product = m.nftData.product;
              m.status = m.nftData.status;
            });
          }
          if (params.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }
          isLoading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const changeTypeAction = (t) => {
      if (type.value !== t) {
        list.value = [];
        type.value = t;
        params.value.type = t;
        params.value.page = 1;
        loadList();
      }
    };

    const giveSendAction = (m) => {
      router.push("./give_nft?id=" + m.nft_data_id);
    };

    const goDetailAction = (m) => {
      if (m.status == 1 && m.own_uid == uid.value && type.value === 0) {
        router.push("./my_nft_detail?id=" + m.nft_data_id);
      }
    };

    const showEWMAction = (img) => {
      ImagePreview({
        images: [img],
      });
    };

    return {
      isLoading,
      hasMore,
      avatar,
      uname,
      type,
      fix,
      list,
      uid,
      nft_info,
      isNoXCX: returnWxPayType() == "wechat_h5",

      loadMoreData,
      changeTypeAction,
      giveSendAction,
      goDetailAction,
      showEWMAction,
    };
  },
  methods: {
    changeUserAction() {
      console.log(this.nft_info);
      if (this.nft_info.status === 0) {
        this.$router.push("./register_nft");
      } else if (this.nft_info.status === 1) {
        this.$copyText(this.nft_info.info.account).then(
          () => {
            Toast("复制成功");
          },
          () => {
            Toast("复制失败");
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-bottom: 198px;
  padding-top: 110px;
  max-height: 99999px;
}
.list {
  padding-top: 10px;

  .item {
    width: 686px;
    height: 280px;
    background: #2d2d2d;
    border-radius: 16px;
    margin-left: 32px;
    overflow: hidden;
    margin-top: 32px;

    .item-top {
      height: 200px;
      border-radius: 16px;
      overflow: hidden;
      background: #333;

      .img {
        background: #999;
        width: 200px;
        height: 200px;
      }
    }
    .item-t-right {
      padding: 28px 32px;
      flex: 1;
      overflow: hidden;

      .name {
        height: 44px;
        line-height: 44px;
        font-size: 32px;
        font-weight: 500;
        color: #ffffff;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .time {
        color: #999;
        font-size: 24px;
        margin-top: 16px;
      }

      .go-send {
        display: flex;
        justify-content: flex-end;
        margin-top: 2px;

        div {
          width: 150px;
          height: 64px;
          border-radius: 32px;
          border: 2px solid #666666;
          line-height: 64px;
          text-align: center;
          font-size: 28px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .item-bottom {
      height: 80px;
      margin: 0 32px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
      .sdk {
        flex: 1;
        font-size: 24px;
        font-weight: 500;
        color: #e6cb95;
        margin-right: 20px;
      }
      .btn {
        font-size: 24px;
        font-weight: 400;
        color: #00eeff;
      }
    }
  }
}

.all-title {
  background: #111;
}
.user {
  padding: 48px 0;
  background: #111;
  .avatar {
    padding-left: 32px;
    img {
      width: 128px;
      height: 128px;
      display: block;
      border-radius: 50%;
    }
  }
  .user-center {
    margin: 0 32px;
    overflow: hidden;

    .no {
      color: #666;
      font-size: 24px;
      margin-top: 8px;

      .txt {
        color: #00eeff;
        margin-left: 20px;
        margin-right: 20px;
      }

      .img {
        color: #00eeff;
      }
    }

    .name {
      font-size: 32px;
      color: #fff;
      font-weight: 500;
    }
  }
  .user-btn {
    width: 248px;
    height: 56px;
    border-radius: 32px;
    border: 2px solid #00eeff;
    margin-right: 32px;
    line-height: 56px;
    text-align: center;
    font-weight: 500;
    color: #00eeff;
    font-size: 24px;
  }
}

.tags {
  height: 88px;
  background: #1e1e1e;

  .tag {
    height: 88px;
    flex: 1;
    line-height: 88px;
    text-align: center;
    font-weight: 500;
    color: #666;
    font-size: 28px;
    position: relative;

    .bg {
      display: none;
      width: 40px;
      height: 8px;
      border-radius: 4px;
      background: #fff;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: 8px;
    }

    &.curr {
      color: #fff;

      .bg {
        display: block;
      }
    }
  }
}
</style>
